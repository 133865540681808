<template>
	<div class="container page">

<!--		<div class="header">-->
<!--			<van-nav-bar :title="$t('my.online_service')" class="nav-bar">-->
<!--				<template #left>-->
<!--					<van-icon name="arrow-left" color="#fff" @click="back()" />-->
<!--				</template>-->
<!--			</van-nav-bar>-->
<!--		</div>-->
    <iframe src="https://xya17.top/index/index?code=sYZlml5SmXS8ELYdy3FkUkSNN1sLpcZ5jKi4MYA3XOG1NLxUpNmNLWcmz12qHQoQlj2s4iXw%2FzjgvtmomDR0VshLNZhBAxBLxRoBsOnZh8%2FS7vKKZuG9lF1RoudmuJT4Xg" frameborder="0" style="height: 100vh;width: 100vw;"></iframe>
<!--		<div class="servicelistItem">-->
<!--			<div class="servicelistItemTop">-->
<!--				<img class="servicelistItemImage" src="img/mine/kefu.png">-->
<!--				<div class="servicelistItemText">-->
<!--					{{this.$store.getters.getBaseInfo.name !==undefined ?this.$store.getters.getBaseInfo.name:this.$t("my.title")}}-->
<!--				</div>-->
<!--				<div class="servicelistItemBtn" @click="toServicePage()">-->
<!--					<div class="servicelistItemBtnText">-->
<!--						{{$t("my.contact")}}-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--			<div class="servicelistItemBottom">-->
<!--				<div class="servicelistItemInfoText">-->
<!--					{{$t("my.service_time")}}-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
<!--		<van-collapse v-model="activeNames">-->
<!--			<van-collapse-item :title="config.pay_title" name="1" icon="/img/1.png">-->
<!--				{{config.pay_desc}}-->
<!--			</van-collapse-item>-->
<!--		</van-collapse>-->
<!--		<div class="payments">-->
<!--			<img v-for="(item, index) in items" :src="item.thumb" :key="index" />-->
<!--		</div>-->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeNames: [1],
				items: [],
				config:{},
			};
		},
		created() {
			this.$http({
				method: 'get',
				url: 'banks_list'
			}).then(res => {
				this.items = res.data;
			})
			this.$http({
				method: 'get',
				url: 'base_info'
			}).then(res => {
				this.config = res.data
			})
		},
		methods: {
			back() {
				return window.history.back();
			},
			toServicePage() {
				const service = this.$store.getters.getBaseInfo;
				console.log(service)
				if (service.iskefu == 1) {
					console.log('ssss')
					window.location.href = service.kefu
				}
				// this.$router.push("ServicePage");
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	/deep/ .van-hairline--top-bottom {
		padding: 20px;
	}

	/deep/ .van-collapse-item {
		border-radius: 10px;
		overflow: auto;
	}

	/deep/ .van-cell__title {
		font-size: large;
		padding: 10px 0px;
	}

	/deep/ .van-collapse-item__content{
		font-size: 32px !important;
	}

	/deep/ .van-icon__image{
		width: 3em;
		height: 3em;
	}

	.payments {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 10px;
	}

	.payments>img {
		padding: 10px;
		width: 50%;
		height: 200px;
	}

	.servicelistItem {
		display: flex;
		flex-direction: column;
		height: 200px;
		padding: 30px 30px;
		margin: 30px 20px;
		border-radius: 20px;
		justify-content: space-between;
		background: #fff;
	}

	.servicelistItem .servicelistItemTop {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 100px;
	}

	.servicelistItem .servicelistItemTop .servicelistItemImage {
		width: 80px;
		height: 80px;
		border-radius: 50%;
	}

	.servicelistItem .servicelistItemTop .servicelistItemText {
		margin-left: 50px;
		font-size: 43px;
		font-weight: 700;
		color: #000;
		flex: 1;
	}

	.servicelistItem .servicelistItemTop .servicelistItemBtn {
		display: flex;
		width: 150px;
		height: 55px;
		border-radius: 30px;
		align-items: center;
		justify-content: center;
		background: linear-gradient(270deg, #e6c3a1, #7e5678);
	}

	.servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText {
		color: #fff;
		font-size: 25px;
	}

	.servicelistItem .servicelistItemBottom {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		background: #f2f2f5;
		border-radius: 10px;
		color: #979799;
	}

	.servicelistItem .servicelistItemBottom .servicelistItemInfoText {
		font-size: 30px;
	}
</style>

<template>
  <div class="container">
    <div id="dice" class="dice">
      <div class="face front"><img alt="1" src="./img/dot1.png"></div>
      <div class="face back"><img alt="2" src="./img/dot2.png"></div>
      <div class="face right"><img alt="3" src="./img/dot3.png"></div>
      <div class="face left"><img alt="4" src="./img/dot4.png"></div>
      <div class="face top"><img alt="5" src="./img/dot5.png"></div>
      <div class="face bottom"><img alt="6" src="./img/dot6.png"></div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.dice {
  position: relative;
  transform-style: preserve-3d;
  //匀速动画
  animation: rotateDice 1000ms infinite linear;
  //旋转中心在中心
  transform-origin: center;
  width: calc(100vw / 20);
  height: calc(100vw / 20);
}

.face {

  position: absolute;
  width: calc(100vw / 20);
  height: calc(100vw / 20);

  img {
    width: 100%;
    height: 100%;
  }
}

.face img {
  width: 100%;
  height: 100%;
}

.front {
  transform: translateZ(calc(100vw / 40));
}

.back {
  transform: rotateY(180deg) translateZ(calc(100vw / 40));
}

.right {
  transform: rotateY(90deg) translateZ(calc(100vw / 40));
}

.left {
  transform: rotateY(-90deg) translateZ(calc(100vw / 40));
}

.top {
  transform: rotateX(90deg) translateZ(calc(100vw / 40));
}

.bottom {
  transform: rotateX(-90deg) translateZ(calc(100vw / 40));
}

@keyframes rotateDice {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  25% {
    transform: rotateX(90deg) rotateY(0deg);
  }
  10% {
    transform: rotateX(90deg) rotateY(90deg);
  }
  75% {
    transform: rotateX(180deg) rotateY(90deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

</style>
